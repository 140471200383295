$black: #333;
$gray: #8D8D8D;
$light-gray: #E6E6E6;
$main: #008C50;
$sub: #74BD9D;
$light-main: #EEF7F3;
$scol01: #FCA032; //オレンジ（企業向け社員研修）
$scol02: #74B5E4; //みずいろ（ICT研修）
$scol03: #85BC2D; //きみどり（医療）
$scol04: #46B7B3; //あおみどり（人材）
$scol05: #D15ABA; //むらさき（BPO）
$scol06: #F876A8; //ピンク（放課後）
$scol07: #F53F3F; //赤（施設）
$scol01-sub: #FFFAF2;
$scol02-sub: #F1F7FD;
$scol03-sub: #ECF5DC;
$scol04-sub: #76CECB;
$scol05-sub: #F3D1EE;
$scol06-sub: #F3B6CD;
$scol07-sub: #FCEAE6;
$scol07-mid: #F76A6A;
$pcol: #E65DA7;
$pcol-sub: #F290C5;
$cscol: #1BA1D0;
$col-sg: #019fe8;


$grade: linear-gradient(90deg, rgba(134,203,97,1) 0%, rgba(0,140,80,1) 100%);
$grade_rev: linear-gradient(90deg, rgba(0,140,80,1) 0%, rgba(134,203,97,1) 100%);;

$base-lh: 2;
$base-fs: 15px;
$sp-fs: 13px;

$mincho: "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "ＭＳ 明朝", serif;
$gothic: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "メイリオ", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

$font-notosans: 'Noto Sans JP', sans-serif; /* Regular 400, Bold 700 */
$font-notoserif: 'Noto Serif JP', serif; /* Medium 500 */
$font-poppins: 'Poppins', sans-serif;
