.bgt-box__image-container {
  text-align: center;
}

.bgt-ckeditor-container {
  a:not(.bgt-btn) {
    color: $black;
    text-decoration: underline;
  }
}
.bgt-box__caption {
  font-size: 18px;
  text-align: center;
}

.bge-contents {
// マージン
  [data-bgb] {
    margin: 30px 0;
    @include mq(sp) {
      margin: 0 0 20px;
    }
  }
  .bgb-opt--mb-large {
    margin-bottom: 100px !important;
    @include mq(sp) {
      margin-bottom: 40px !important;
    }
  }
// 最大幅指定
  [data-bgb]:not(.bge-wide):not(.bge-img) {
    @include base(1040,20,5vw);
  }
// .bge-wide 背景無し
  [data-bgb].bge-wide {
    padding: 50px 0;
    @include mq(sp) {
      padding: 25px 0;
    }
// .bge-wide.main 幅いっぱい背景色
    &.col-01 {
      background: $scol01-sub;
    }
// .bge-wide.gray 幅いっぱい背景色灰色
    &.gray {
      background: $light-gray;
    }
// .bge-wide内コンテンツ幅
    [data-bgt] {
      @include base(1040,20,5vw);
    }
    &.wide [data-bgt] {
      @include base(1240,20,5vw);
    }
  }

  [data-bgb].bge-bg01 {
    padding: 40px !important;
    background: $scol01-sub;
    @include mq(sp) {
      padding: 20px !important;
    }
    [data-bgt=ckeditor] {
      @include mq(sp) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  [data-bgb].bge-bg02 {
    padding: 40px !important;
    background: $scol02-sub;
    @include mq(sp) {
      padding: 20px !important;
    }
  }
  [data-bgb].bge-bg03 {
    padding: 40px !important;
    background: $scol03-sub;
    @include mq(sp) {
      padding: 20px !important;
    }
  }
  [data-bgb].bge-bg04 {
    padding: 40px !important;
    background: $scol04-sub;
    @include mq(sp) {
      padding: 20px !important;
    }
  }
  [data-bgb].bge-bg05 {
    padding: 40px !important;
    background: $scol05-sub;
    @include mq(sp) {
      padding: 20px !important;
    }
  }
  [data-bgb].bge-bg06 {
    padding: 40px !important;
    background: $scol06-sub;
    @include mq(sp) {
      padding: 20px !important;
    }
  }
  [data-bgb].bge-bg07 {
    padding: 40px !important;
    background: $scol07-sub;
    @include mq(sp) {
      padding: 20px !important;
    }
  }

  .bgb-opt--bg-blue, .bgb-opt--bg-gray, .bgb-opt--bg-pink {
    max-width: 1000px !important;
    padding: 30px 50px !important;
    @include mq(sp) {
      padding: 20px 30px !important;
    }
  }


// .bge-img 内画像
  [data-bgb].bge-img {
    [data-bgt] {
      img {
        width: 100%;
      }
    }
  }

  .ttl-image {
    figure {
      position: relative;
      display: block;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        display: block;
        width: 100%;
        height: 270px;
        background: rgba(51,51,51,.5);
        z-index: 0;
        @include mq(sp) {
          height: 135px;
        }
      }
      &::after {
        position: absolute;
        bottom: 0;
        right: 0;
        content: "";
        display: block;
        width: 468px;
        height: 225px;
        background: url(../img/common/bg_mv_page_wh.png) bottom right no-repeat;
        background-size: 468px auto;
        z-index: 2;
        @include mq(sp) {
          width: 177px;
          height: 85px;
          background-image: url(../img/common/sp/bg_mv_page_wh_sp.png);
          background-size: 177px auto;
        }
      }
      img {
        width: 100%;
        height: 270px;
        object-fit: cover;
        @include mq(sp) {
          height: 135px;
        }
      }
      figcaption {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        color: #fff;
        font-size: 37px;
        font-weight: 700;
        text-shadow: 0 0 5px rgba(0,0,0,.2);
        transform: translate(-50%,-50%);
        z-index: 10;
        @include mq(sp) {
          font-size: 18px;
          white-space: nowrap;
        }
      }
    }
  }
}

// レスポンシブ切り替え対応
.bge-contents.bge_contents.bge_content {
  .responsive {
    &_pc {
      position: relative;
      padding-bottom: 10px;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: auto;
        z-index: 100;
        content: "　パソコン版　";
        width: auto;
        height: auto;
        background: #ff0;
        color: #000;
        font-size: 16px;
        line-height: 1.5;
      }
    }
    &_sp {
      position: relative;
      text-align: center;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: auto;
        z-index: 100;
        content: "　スマホ版　";
        width: auto;
        height: auto;
        background: #ff0;
        color: #000;
        font-size: 16px;
        line-height: 1.5;
      }
      img {
        width: 40%;
      }
    }
  }
}
.wrapper .main .bge-contents {
  .responsive {
    &_pc {
      display: block;
      @include mq(sp) {
        display: none;
      }
    }
    &_sp {
      display: none;
      @include mq(sp) {
        display: block;
      }
    }
  }
}

// flexbox
.flex-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .flex-grid {
    width: 48%;
    @include mq(sp) {
      width: 100%;
      margin-bottom: 20px;
    }
    &:last-child {
      @include mq(sp) {
        margin-bottom: 0;
      }
    }
    .bgt-image-container {
      margin-bottom: 10px;
    }
    .bgt-ckeditor-container {
    }
  }
}

// 3カラムWYSIWYG
[data-bgb=wysiwyg3] {
  display: flex;
  @include mq(sp) {
    display: block;
  }
  .bgt-grid {
    width: 32%;
    margin-right: 2%;
    @include mq(sp) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
    &--last {
      margin-right: 0;
    }
  }
}

.btn-def [data-bgt-button-kind='link'] {
    
}

.col-03 {
  .bgt-btn {
    border: 1px solid $scol03;
    background: #FFF url(/asset2022/img/common/icn_arrow_gr.svg) right 30px center no-repeat;
    color: $scol03;
    &:hover {
      background-color: $scol03-sub;
      background-image: url(/asset2022/img/common/icn_arrow_wh.svg);
      color: $scol03;
    }
  }
}

.col-06 {
  .bgt-btn {
    border: 1px solid $scol06;
    background: #FFF url(/asset2022/img/common/icn_arrow_06.svg) right 30px center no-repeat;
    color: $scol06;
    &:hover {
      background-color: $scol06-sub;
      background-image: url(/asset2022/img/common/icn_arrow_wh.svg);
      color: #fff;
    }
  }
}

.col-person {
  .bgt-btn {
    border: 1px solid $pcol;
    background: #FFF url(/asset2022/img/common/icn_arrow_p.svg) right 30px center no-repeat;
    color: $scol06;
    &:hover {
      background-color: $pcol-sub;
      background-image: url(/asset2022/img/common/icn_arrow_wh.svg);
      color: #fff;
    }
  }
}


[data-bgb] {
  table {
    @include box-sizing(border-box);
    margin-bottom: 60px;
    border-top: 1px solid $gray;
    @include mq(sp) {
      margin-bottom: 30px;
    }
    tr {
      border-bottom: 1px solid $gray;
    }
    th {
      @include box-sizing(border-box);
      padding: 20px;
      background: $light-main;
      color: $black;
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      vertical-align: top;
      @include mq(sp) {
        line-height: 1.2;
      }
    }
    td {
      @include box-sizing(border-box);
      padding: 20px;
      font-size: 15px;
      line-height: 1.5;
      @include mq(sp) {
        font-size: 15px;
      }
    }
  }

  table {
    th {
      @include mq(sp) {
        display: block;
        width: 100%;
        padding: 10px 15px;
      }
    }
    td {
      @include mq(sp) {
        display: block;
        width: 100%;
        padding: 15px 0;
      }
    }
  }

  &.next {
    position: relative;
    &::after {
      position: absolute;
      bottom: -70px;
      left: 100px;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border: 30px solid transparent;
      border-top: 20px solid #ccc;
      @include mq(sp) {
        left: 50%;
        bottom: -55px;
        margin-left: -30px;
      }
    }
  }
}


[data-bgb=button2] .bgt-grid--last, [data-bgb=download-file2] .bgt-grid--last, [data-bgb=image2] .bgt-grid--last, [data-bgb=image-link2] .bgt-grid--last, [data-bgb=image-text2] .bgt-grid--last, [data-bgb=image-link-text2] .bgt-grid--last, [data-bgb=trimmed-image2] .bgt-grid--last, [data-bgb=trimmed-image-link2] .bgt-grid--last, [data-bgb=wysiwyg2] .bgt-grid--last {
    padding-left: 30px;
    @include mq(sp) {
      padding-left: 0;
    }
}
[data-bgb=button2] .bgt-grid--first, [data-bgb=download-file2] .bgt-grid--first, [data-bgb=image2] .bgt-grid--first, [data-bgb=image-link2] .bgt-grid--first, [data-bgb=image-text2] .bgt-grid--first, [data-bgb=image-link-text2] .bgt-grid--first, [data-bgb=trimmed-image2] .bgt-grid--first, [data-bgb=trimmed-image-link2] .bgt-grid--first, [data-bgb=wysiwyg2] .bgt-grid--first {
    padding-right: 30px;
    @include mq(sp) {
      padding-right: 0;
    }
}

[data-bgb=text-image2] .bgt-grid--first {
  padding-right: 20px;
  @include mq(sp) {
    padding-right: 0;
  }
}
[data-bgb=text-image2] .bgt-grid--last {
  padding-left: 20px;
  @include mq(sp) {
    padding-left: 0;
  }
}

[data-bgb=wysiwyg2] .bgt-grid [data-bgt] > :last-child {
  margin-bottom: 0 !important;
}

[data-bgb] .bgt-grid {
  @include mq(sp) {
    margin-bottom: 20px;
  }
  &--last {
    @include mq(sp) {
      margin-bottom: 0;
    }
  }
}

.bge-contents {
  h2:not(.bge-title-h2) {
    margin-top: 0;
    padding: 13px 30px;
    background: $main;
    background: linear-gradient(90deg,#008c50 0,#86cb61 100%);
    color: #fff;
    font-size: 28px;
    font-weight: 500;
    line-height: 1.2;
    @include mq(sp) {
      padding: 10px 20px;
      font-size: 20px;
      line-height: 1.5;
    }
    .subbox {
      margin-left: 20px;
      padding: 0 10px;
      border: 1px solid #fff;
      font-size: 20px;
      font-weight: 400;
      @include mq(sp) {
        display: block;
        margin-left: 0;
        font-size: 15px;
        text-align: center;
      }
    }
    .subtitle {
      font-size: 20px;
      @include mq(sp) {
        display: block;
        font-size: 15px;
      }
    }
    .ta-center {
      text-align: center;
    }
  }
  h2 {
    margin-top: 0;
    padding: 13px 30px;
    background: $light-main;
    color: $main;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    @include mq(sp) {
      padding: 10px 20px;
      font-size: 20px;
      line-height: 1.5;
    }
    .subbox {
      margin-left: 20px;
      padding: 0 10px;
      border: 1px solid #fff;
      font-size: 20px;
      font-weight: 400;
      @include mq(sp) {
        display: block;
        margin-left: 0;
        font-size: 15px;
        text-align: center;
      }
    }
    .subtitle {
      font-size: 20px;
      @include mq(sp) {
        display: block;
        font-size: 15px;
      }
    }
  }
  h3:not(.bge-title-h3) {
    padding: 0 0 10px 30px;
    border-bottom: 1px solid #CECECE;
    border-left: 1px solid #8D8D8D;
    color: $black;
    font-size: 24px;
    text-align: left;
    @include mq(sp) {
      padding: 0 0 6px 20px;
      font-size: 18px
    }
  }
  h3.bge-title-h3 {
    padding: 0;
    color: $black;
    font-size: 20px;
    border: 0;
    @include mq(sp) {
      font-size: 17px;
    }
    &::before {
      content: "●";
      margin-right: 6px;
    }
  }
}

.title-01 {
  h2 {
    margin-top: 0;
    padding: 13px 30px;
    background: $main;
    color: #fff;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.2;
    @include mq(sp) {
      padding: 10px 20px;
      font-size: 20px;
      line-height: 1.5;
    }
    .subbox {
      margin-left: 20px;
      padding: 0 10px;
      border: 1px solid #fff;
      font-size: 20px;
      font-weight: 400;
      @include mq(sp) {
        display: block;
        margin-left: 0;
        font-size: 15px;
        text-align: center;
      }
    }
    .subtitle {
      font-size: 20px;
      @include mq(sp) {
        display: block;
        font-size: 15px;
      }
    }
    .ta-center {
      text-align: center;
    }
  }
  &.col-01 h2 {
    background: $scol01;
  }
  &.col-02 h2 {
    background: $scol02;
  }
  &.col-03 h2 {
    background: $scol03;
  }
  &.col-04 h2 {
    background: $scol04;
  }
  &.col-05 h2 {
    background: $scol05;
  }
  &.col-06 h2 {
    background: $scol06;
  }
  &.col-07 h2 {
    background: $scol07;
  }
  &.col-p h2 {
    background: $pcol;
  }
  [data-bgt=title-h2] {
    &::before {
      display: none;
    }
  }
}

.title-02 {
  h2 {
    padding: 0 0 10px 30px;
    border-bottom: 1px solid #CECECE;
    border-left: 1px solid #8D8D8D;
    background: none;
    color: $black;
    font-size: 24px;
    line-height: 1.8;
    text-align: left;
    @include mq(sp) {
      padding-left: 20px;
      font-size: 18px;
    }
  }
  [data-bgt=title-h2] {
    &::before {
      display: none;
    }
  }
  h3 {
    padding: 0 0 10px 30px;
    border-bottom: 1px solid #CECECE;
    border-left: 1px solid #8D8D8D;
    color: $black;
    font-size: 24px;
    text-align: left;
    @include mq(sp) {
      padding: 0 0 6px 20px;
      font-size: 18px
    }
  }
}
[data-bgb] {
  .title-02 {
    padding: 0 0 10px 20px;
    border-bottom: 1px solid #CECECE;
    border-left: 1px solid #8D8D8D;
    color: $black;
    font-size: 23px;
    font-weight: 700;
    text-align: left;
    @include mq(sp) {
      font-size: 18px;
    }
  }
}

.title-03 {
  h2 {
    color: $black;
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    @include mq(sp) {
      font-size: 18px;
    }
  }
  [data-bgt=title-h2] {
    &::before {
      display: none;
    }
  }
  h3 {
    color: $black;
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    @include mq(sp) {
      font-size: 18px;
    }
  }
  &.col-01 {
    h2, h3 {
      color: $scol01;
    }
  }
  &.col-02 {
    h2, h3 {
      color: $scol02;
    }
  }
  &.col-03 {
    h2, h3 {
      color: $scol03;
    }
  }
  &.col-04 {
    h2, h3 {
      color: $scol04;
    }
  }
  &.col-05 {
    h2, h3 {
      color: $scol05;
    }
  }
  &.col-06 {
    h2, h3 {
      color: $scol06;
    }
  }
  &.col-07 {
    h2, h3 {
      color: $scol07;
    }
  }
  .ta-center {
    text-align: center;
  }
}
[data-bgb] {
  .title-03 {
    color: $black;
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    @include mq(sp) {
      font-size: 18px;
    }
    &.col-01 {
      color: $scol01;
    }
    &.col-02 {
      color: $scol02;
    }
    &.col-03 {
      color: $scol03;
    }
    &.col-04 {
      color: $scol04;
    }
    &.col-05 {
      color: $scol05;
    }
    &.col-06 {
      color: $scol06;
    }
    &.col-07 {
      color: $scol07;
    }
  }
}

.title-04 {
  margin-bottom: 20px;
  background: linear-gradient(0deg,white 0,white 5%,$scol03-sub 5%,$scol03-sub 40%,white 40%,white 100%);
  font-size: 28px;
  font-weight: 700;
  @include mq(sp) {
    margin-bottom: 10px;
    font-size: 20px;
  }
  h2 {
    background: none;
  }
}
[data-bgb] {
  .title-04 {
    display: inline;
    margin-bottom: 20px;
    background: linear-gradient(0deg,white 0,white 5%,$scol03-sub 5%,$scol03-sub 40%,white 40%,white 100%);
    font-size: 28px;
    font-weight: 700;
    @include mq(sp) {
      margin-bottom: 10px;
      font-size: 20px;
    }
    &.col-01 {
      background: linear-gradient(0deg,white 0,white 5%,$scol01-sub 5%,$scol01-sub 40%,white 40%,white 100%);
    }
    &.col-02 {
      background: linear-gradient(0deg,white 0,white 5%,$scol02-sub 5%,$scol02-sub 40%,white 40%,white 100%);
    }
    &.col-03 {
      background: linear-gradient(0deg,white 0,white 5%,$scol03-sub 5%,$scol03-sub 40%,white 40%,white 100%);
    }
    &.col-04 {
      background: linear-gradient(0deg,white 0,white 5%,$scol04-sub 5%,$scol04-sub 40%,white 40%,white 100%);
    }
    &.col-05 {
      background: linear-gradient(0deg,white 0,white 5%,$scol05-sub 5%,$scol05-sub 40%,white 40%,white 100%);
    }
    &.col-06 {
      background: linear-gradient(0deg,white 0,white 5%,$scol06-sub 5%,$scol06-sub 40%,white 40%,white 100%);
    }
    &.col-07 {
      background: linear-gradient(0deg,white 0,white 5%,$scol07-sub 5%,$scol07-sub 40%,white 40%,white 100%);
    }
  }
}

.title-05 {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
  @include mq(sp) {
    margin-top: 5px;
    font-size: 16px;
  }
}
[data-bgb] {
  .title-05 {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 700;
    @include mq(sp) {
      margin-top: 5px;
      font-size: 16px;
    }
  }
}

.title-06 {
  h2, h3 {
    margin-top: 0;
    padding: 13px 30px !important;
    border: none;
    background: $main;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    @include mq(sp) {
      padding: 10px 20px !important;
      font-size: 18px;
      line-height: 1.5;
    }
    &::before {
      display: none;
    }
    .subtitle {
      font-size: 14px;
      @include mq(sp) {
        font-size: 12px;
      }
    }
  }
  &.col-01 {
    h2, h3 {
      background: $scol01-sub;
      color: $scol01;
    }
  }
  &.col-02 {
    h2, h3 {
      background: $scol02-sub;
      color: $scol02;
    }
  }
  &.col-03 {
    h2, h3 {
      background: $scol03-sub;
      color: $scol03;
    }
  }
  &.col-04 {
    h2, h3 {
      background: $scol04-sub;
      color: $scol01;
    }
  }
  &.col-05 {
    h2, h3 {
      background: $scol05-sub;
      color: $scol05;
    }
  }
  &.col-06 {
    h2, h3 {
      background: $scol06-sub;
      color: $scol06;
    }
  }
  &.col-07 {
    h2, h3 {
      background: $scol07-mid;
      color: #fff;
    }
  }
  [data-bgt=title-h2] {
    &::before {
      display: none;
    }
  }
}

.title-07 {
  h2, h3 {
    margin-top: 0;
    padding: 13px 30px;
    border: none;
    background: $sub;
    color: #fff;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.2;
    text-align: left;
    @include mq(sp) {
      padding: 10px 20px;
      font-size: 18px;
      line-height: 1.5;
    }
  }
  &.col-01 {
    h2, h3 {
      background: $scol01-sub;
    }
  }
  &.col-02 {
    h2, h3 {
      background: $scol02-sub;
    }
  }
  &.col-03 {
    h2, h3 {
      background: $scol03-sub;
    }
  }
  &.col-04 {
    h2, h3 {
      background: $scol04-sub;
    }
  }
  &.col-05 {
    h2, h3 {
      background: $scol05-sub;
    }
  }
  &.col-06 {
    h2, h3 {
      background: $scol06-sub;
    }
  }
  &.col-07 {
    h2, h3 {
      background: $scol07-mid;
    }
  }
  [data-bgt=title-h2] {
    &::before {
      display: none;
    }
  }
}

[data-bgb].copy-01 {
  font-family: $font-notoserif;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.5;
  @include mq(sp) {
    font-size: 18px;
  }
}
[data-bgb] {
  .copy-01 {
    margin-bottom: 20px;
    font-family: $font-notoserif;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.5;
    @include mq(sp) {
      margin-bottom: 10px;
      font-size: 18px;
    }
  }
}

[data-bgb].copy-02 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  @include mq(sp) {
    font-size: 18px;
  }
}
[data-bgb] {
  .copy-02 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
    @include mq(sp) {
      margin-bottom: 10px;
      font-size: 18px;
    }
  }
}

[data-bgb].copy-03 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  @include mq(sp) {
    font-size: 16px;
  }
}
[data-bgb] {
  .copy-03 {
    margin: 10px 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    @include mq(sp) {
      margin-bottom: 10px;
      font-size: 16px;
    }
  }
}

[data-bgb="wysiwyg"] {
  .bge-ckeditor {
    p {
      &:not(:last-child) {
        margin-bottom: 20px;
        @include mq(sp) {
          margin-bottom: 10px;
        }
      }
    }
  }
}


[data-bgb] {
  .inbox {
    display: inline-block;
    border: 1px solid $scol01;
    margin-bottom: 10px;
    padding: 3px 20px;
    color: $scol01;
    font-size: 15px;
    line-height: 1.5;
    @include mq(sp) {
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
}

[data-bgb].box-01 {
  .bgt-grid--first {
    padding: 15px 20px 15px 30px;
    background: $sub;
    color: #fff;
    border-radius: 4px;
    font-weight: 700;
    line-height: 1.5;
  }
  .bgt-grid--last {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.7;
  }
}

// bge custom class
[data-bgb].title-service {
  [data-bgt] {
    background: $main;
    h1 {
      @include base(1040,20,5vw);
      position: relative;
      height: 100px;
      padding-left: 150px;
      color: #fff;
      font-size: 36px;
      font-weight: 700;
      line-height: 94px;
      @include mq(sp) {
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 20px;
        line-height: 1.5;
      }
      &::after {
        position: absolute;
        top: -40px;
        left: 13px;
        content: "";
        display: block;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.16));
        background: url(../img/top/icn_service_01.png) 0 0 no-repeat;
        background-size: 120px 120px;
        @include mq(sp) {
          top: -15px;
          left: auto;
          right: 10px;
          width: 57px;
          height: 57px;
          background-size: 57px 57px;
        }
      }
      .subtitle {
        font-size: 24px;
        @include mq(sp) {
          display: block;
          font-size: 16px;
        }
      }
    }
  }
  &.col-01 [data-bgt] {
    background: $scol01;
    h1::after {
      background-image: url(../img/top/icn_service_01.png);
    }
  }
  &.col-02 [data-bgt] {
    background: $scol02;
    h1::after {
      background-image: url(../img/top/icn_service_02.png);
    }
  }
  &.col-03 [data-bgt] {
    background: $scol03;
    h1::after {
      background-image: url(../img/top/icn_service_03.png);
    }
  }
  &.col-04 [data-bgt] {
    background: $scol04;
    h1::after {
      background-image: url(../img/top/icn_service_04.png);
    }
  }
  &.col-05 [data-bgt] {
    background: $scol05;
    h1::after {
      background-image: url(../img/top/icn_service_05.png);
    }
  }
  &.col-06 [data-bgt] {
    background: $scol06;
    h1::after {
      background-image: url(../img/top/icn_service_06.png);
    }
  }
  &.col-07 [data-bgt] {
    background: $scol07;
    h1::after {
      background-image: url(../img/top/icn_service_07.png);
    }
  }
}

// 数字付きボックス
[data-bgb=wysiwyg2].bge-box,
[data-bgb=wysiwyg2-ex].bge-box {
  max-width: 1000px !important;
  padding: 20px 30px !important;
  border: 1px solid $scol01;
  border-top: none;
  background: $scol01-sub;
  @include mq(sp) {
    margin-left: 5vw !important;
    margin-right: 5vw !important;
  }
  .bgt-grid--first {
    @include mq(pc) {
      border-right: 1px solid #707070;
    }
    @include mq(sp) {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #707070;
    }
  }
  &.number {
    .bgt-grid--first {
      position: relative;
      padding-left: 90px;
      @include mq(sp) {
        padding-left: 50px;
      }
      &::before {
        position: absolute;
        left: 20px;
        top: 0;
        display: block;
        content: '1';
        color: $scol01;
        font-family: $font-poppins;
        font-size: 120px;
        font-weight: 700;
        line-height: 1;
        opacity: .4;
        @include mq(sp) {
          left: 10px;
          font-size: 60px;
        }
      }
    }
    .bgt-grid--last {
      position: relative;
      padding-left: 120px;
      @include mq(sp) {
        padding-left: 50px;
      }
      &::before {
        position: absolute;
        left: 20px;
        top: 0;
        display: block;
        content: '2';
        color: $scol01;
        font-family: $font-poppins;
        font-size: 120px;
        font-weight: 700;
        line-height: 1;
        opacity: .4;
        @include mq(sp) {
          left: 0;
          font-size: 60px;
        }
      }
    }
  }
  &.col-01 {
    border: 1px solid $scol01;
    background: $scol01-sub;
    &.number .bgt-grid--first::before,
    &.number .bgt-grid--last::before {
        color: $scol01;
    }
  }
  &.col-02 {
    border: 1px solid $scol02;
    background: $scol01-sub;
    &.number .bgt-grid--first::before,
    &.number .bgt-grid--last::before {
        color: $scol02;
    }
  }
  &.col-03 {
    border: 1px solid $scol03;
    background: $scol01-sub;
    &.number .bgt-grid--first::before,
    &.number .bgt-grid--last::before {
        color: $scol03;
    }
  }
  &.col-04 {
    border: 1px solid $scol04;
    background: $scol04-sub;
    &.number .bgt-grid--first::before,
    &.number .bgt-grid--last::before {
        color: $scol04;
    }
  }
  &.col-05 {
    border: 1px solid $scol05;
    background: $scol05-sub;
    &.number .bgt-grid--first::before,
    &.number .bgt-grid--last::before {
        color: $scol05;
    }
  }
  &.col-06 {
    border: 1px solid $scol06;
    background: $scol01-sub;
    &.number .bgt-grid--first::before,
    &.number .bgt-grid--last::before {
        color: $scol06;
    }
  }
  &.col-07 {
    border: 1px solid $scol07;
    background: $scol07-sub;
    &.number .bgt-grid--first::before,
    &.number .bgt-grid--last::before {
        color: $scol07;
    }
  }

  .text-title {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
    @include mq(sp) {
      margin-bottom: 5px;
      font-size: 18px;
    }
  }
  .text-box {
    @include mq(sp) {
      line-height: 1.8;
    }
  }
}

[data-bgb=wysiwyg-ex].bge-tbox,
[data-bgb=wysiwyg2-ex].bge-tbox,
[data-bgb=wysiwyg3-ex].bge-tbox {
  .text-title {
    padding: 3px 0;
    background: $gray;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
  }
  .text-box {
    //height: calc(100% - 42px);
    padding: 10px 15px;
    border-left: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    border-right: 1px solid $light-gray;
    font-size: 13px;
    @include mq(sp) {
      font-size: 12px;
    }
  }
  &.col-07 {
    .text-title {
      background-color: $scol07-mid;
      color: #fff;
    }
  }
  &.li-2col {
    ul {
      display: flex;
      flex-wrap: wrap;
      @include mq(sp) {
        display: block;
      }
      li {
        width: 50%;
        font-size: 15px;
        @include mq(sp) {
          width: 100%;
          font-size: 13px;
        }
      }
    }
  }
}

[data-bgb].bge-dbox {
  max-width: 1000px !important;
  padding: 20px 30px !important;
  border: 1px solid $scol01;
  background: $scol01-sub;
  @include mq(sp) {
    margin-left: 5vw !important;
    margin-right: 5vw !important;
    padding: 10px 15px !important;
  }
  &.col-01 {
    border: 1px solid $scol01;
    background: $scol01-sub;
  }
  &.col-02 {
    border: 1px solid $scol02;
    background: $scol02-sub;
  }
  &.col-03 {
    border: 1px solid $scol03;
    background: $scol03-sub;
  }
  &.col-04 {
    border: 1px solid $scol04;
    background: $scol01-sub;
  }
  &.col-05 {
    border: 1px solid $scol05;
    background: $scol05-sub;
  }
  &.col-06 {
    border: 1px solid $scol06;
    background: $scol06-sub;
  }
  &.col-07 {
    border: 1px solid $scol07;
    background: $scol07-sub;
  }
  &.col-p {
    border: 1px solid $pcol;
    background: #fff;
  }
  &.separate {
    .bgt-grid--first {
      @include mq(pc) {
        border-right: 1px solid #BEBEBE;
      }
    }
  }

  .text-title {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
    @include mq(sp) {
      margin-bottom: 5px;
      font-size: 18px;
    }
  }
  .text-box {
    @include mq(sp) {
      line-height: 1.8;
    }
  }
}

[data-bgb=wysiwyg].bge-border {
  max-width: 1000px !important;
  padding: 20px 40px !important;
  border: 1px solid #707070;
  font-size: 15px;
  @include mq(sp) {
    margin-left: 5vw !important;
    margin-right: 5vw !important;
    padding: 10px 20px !important;
    font-size: 13px;
  }
}
[data-bgb=flexbox].bge-border,
[data-bgb=flexbox3].bge-border {
  .flex-grid {
    padding: 20px 40px;
    border: 1px solid #707070;
    font-size: 15px;
    @include mq(sp) {
      padding: 10px 20px;
      font-size: 13px;
    }
  }
}

.ict-award {
  img {
    @include mq(sp) {
      width: 40%;
    }
  }
  ul {
    li {
      @include mq(sp) {
        margin-bottom: 5px;
      }
      .year {
        @include mq(sp) {
          display: block;
        }
      }
      .title {
        @include mq(sp) {
          display: block;
          line-height: 1.5;
        }
      }
    }
  }
}